import { UnleashService } from '@snowfrog/ngx-unleash-proxy-client'
import Keycloak from 'keycloak-js'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { featureFlags } from 'src/app/shared/models/other-module'
import { RoleDepartment } from 'src/app/shared/models/user-info'
import { environment } from 'src/environments/environment'

import { AuthService } from '../auth/auth.service'
import { LocalStorageService } from '../storage/local-storage.service'

export const initializeKeycloak = (authService: AuthService, storage: LocalStorageService, unleash: UnleashService) => {
  return () => {
    // realm name will be the subdomain
    const keycloak: Keycloak = new Keycloak({
      realm: environment.keycloakRealm,
      url: environment.keycloakBaseUrl,
      clientId: environment.keycloakClientId
    })

    return keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false
      })
      .then(async () => {
        authService.keycloak = keycloak
        const response = await authService.getUserInfo()
        if (response['code'] && response['code'] == 401) {
          authService.setUserInfoMessage('AccountDisabled')
          return false
        }
        return authService.keycloak
          .loadUserInfo()
          .then(() => {
            // return authService.keycloak.loadUserProfile()
            if (authService.keycloak.authenticated) return authService.keycloak.loadUserProfile()
          })
          .then(() => {
            setStorageValues(response)
            authService.getCompanyInfo().subscribe(response => {
              if (response) {
                // environment.jitsuClientKey = response.jitsuClientKey

                // environment.jitsuTrackingHost = response.jitsuTrackingHost

                delete response.jitsuClientKey
                delete response.jitsuTrackingHost
                storage.set(StorageKeys.companyProfile, response)
              }
            })
            return true
          })
      })
      .catch(() => false)
  }

  function setStorageValues(response) {
    if (!response.isAdmin) {
      authService.setUserInfoMessage('NotAdmin')
      return
    }

    const userinfo = {
      _id: response?.id,
      isMasterEntry: response?.isMasterEntry,
      status: response?.status,
      emailId: response?.emailId,
      fullName: response?.fullName,
      username: response?.username,
      isAdmin: response?.isAdmin
    }
    const { role, department } = response
    const permissions = {
      isApprover: role?.isApprover,
      canCreateUsers: role?.isCreateUser,
      canManageUsers: role?.isManageUsers,
      miscellaneousAccess: role?.miscellaneousAccess,
      docLibraryAccess: role?.docLibraryAccess,
      orgSettingsAccess: role?.orgSettingsAccess,
      selfServiceAccess: role?.selfServiceAccess,
      hasLMSAdminAccess: role?.hasLMSAdminAccess,
      dtSessionRead: role?.dtSessionRead,
      globalSearchReplaceAccess: role?.globalSearchReplaceAccess,
      dtSessionDelete: role?.dtSessionDelete,
      translationAccess: role?.translationAccess,
      canDeleteUsers: role?.canDeleteUsers,
      canChangeDept: role?.canChangeDept
    }

    const departments: RoleDepartment[] = role?.departments.map(dept => ({ id: dept.id, name: dept.name })) || []
    storage.set(StorageKeys.userPermissions, permissions)
    storage.set(StorageKeys.departments, departments)
    storage.set(StorageKeys.authToken, response.authToken)
    storage.set(StorageKeys.userInfo, userinfo)
    storage.set(StorageKeys.serviceDetails, role?.servicePolicies || [])

    // const assignedDeptExistsInRole = departments.find(dept => dept.id === (defaultDepartment?.id || department?.id))

    const selectedDepartment = storage.get(StorageKeys.selectedDepartment) as RoleDepartment

    if (unleash.isEnabled(featureFlags.CAN_ADMIN_CHANGE_DEPT)) {
      if (role?.canChangeDept) {
        // Get selected department from storage or set default if not available
        if ('id' in selectedDepartment && !departments.some(dept => dept.id === selectedDepartment.id)) {
          storage.set(StorageKeys.selectedDepartment, '')
        }
      } else {
        const defaultDept = departments.find(dept => dept.id === department.id)
        if (defaultDept) storage.set(StorageKeys.selectedDepartment, defaultDept)
        else storage.set(StorageKeys.selectedDepartment, '')
      }
    } else {
      storage.remove(StorageKeys.selectedDepartment)
    }
  }
}
