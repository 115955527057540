export enum ModuleType {
  DT = 'decisiontrees',
  DTT = 'GuidanceTitle',
  PG = 'picture-guides',
  LMS = 'lms',
  FAQ = 'faqs',
  Article = 'articles',
  Collab = 'Collaboration',
  Tickler = 'Tickler',
  Broadcast = 'Broadcast',
  Banner = 'Banner',
  Template = 'Template',
  DocumentLibrary = 'DocumentLibrary'
}
export enum AgentUIModuleType {
  DT = 'decision-tree',
  PG = 'picture-guide',
  FAQ = 'faqs',
  LMS = 'assessments',
  Article = 'articles'
}
export enum ElasticModuleType {
  DT = 'GuidanceTitle',
  PG = 'PictureGuideTitle',
  FAQ = 'FaqTitle',
  Article = 'Article',
  NoResult = 'NoResult',
  Collab = 'Collaboration',
  LMS = 'LMSTitle'
}

export enum MenuType {
  Menu = 0,
  SubMenu,
  ServicePlan,
  Tools
}
export enum Direction {
  LTR = 'ltr',
  RTL = 'rtl'
}

export enum ServiceCode {
  DT = 'DT_29891S',
  PG = 'PG_27488S',
  FAQ = 'FA_39541S',
  Article = 'AR_45309S',
  LMS = 'LMS_29891S',
  LMS_ASSESSMENT = 'LMS_A_29891S'
}

export enum ServiceName {
  MAGICWAND = 'autofix',
  AI_TRANSLATE = 'ai-translate',
  DT = 'Decision-Tree',
  PG = 'Picture-Guide',
  session = 'session',
  FAQ = 'FAQ',
  GLOBALSEARCHANDREPLACE = 'global-search-replace',
  SelfService = 'self-service',
  Translations = 'translations',
  BulkSoftDelete = 'bulk-soft-delete',
  Article = 'Articles',
  LMS = 'Lms',
  UM = 'User-Management',
  MISC = 'Miscellaneous',
  KnowledgeGraph = 'knowledge-graph',
  Client = 'Billing-Module',
  PGVideo = 'PG-Video',
  DocumentLibrary = 'Document-Library',
  GenerateDecisionTree = 'Gen-AI-DT',
  MaxAiDT = 'max-ai-dt',
  Webhook = 'Webhook',
  AuditLog = 'audit-log',
  Sentry = 'sentry',
  Summary = 'Summary',
  Learning = 'learning',
  SyncUser = 'sync-user',
  IMPORT_CONTENT = 'import-content',
  EXPORT_CONTENT = 'export-content',
  PreviewThemeSelection = 'preview-theme-selection',
  SEARCH_CONFIG = 'search-configuration',
  COPY_ASSET_URL = 'copy-asset-url',
  categorySortingDate = 'category-sort-date',
  disableAIKeywords = 'disable-ai-keywords'
}

export const featureFlags = {
  AI_GENERATED_CATEGORY: 'ai-generated-category',
  AI_GENERATED_ARTICLE: 'ai-generated-article',
  recommendCategory: 'recommendCategory',
  DISABLE_CROSS_DEPT_CONTENT_CREATION: 'disable-cross-dept-content-creation',
  LABEL: 'label',
  CAN_ADMIN_CHANGE_DEPT: 'can-admin-change-dept'
}

export const ElasticModuleTypeToModuleType = {
  GuidanceTitle: ModuleType.DT,
  PictureGuideTitle: ModuleType.PG,
  FaqTitle: ModuleType.FAQ,
  Article: ModuleType.Article,
  Collaboration: ModuleType.Collab,
  LMS: ModuleType.LMS
}
export class OtherModule {
  contentId: string
  contentType: ModuleType
  linkModuleId: string
  linkModuleType: ModuleType
  displayName: string
  guidanceTitle: string
}

export class MediaListData {
  createdBy: string
  createdDate: string
  displayName: string
  isMasterEntry: boolean
  isSaveToLibrary: boolean
  mediaName: string
  mediaPath: string
  mimeType: string
  modifiedBy: string
  modifiedDate: string
  status: number
}

export enum SearchSortType {
  Popularity = 0,
  Updation,
  Rating,
  Alphabetically
}

export class LinkAnotherModule {
  contentId: string
  contentTitle: string
  contentType: string
  serviceCode: string
}
export enum StatusTypeTranslation {
  Inactive = 0,
  Active = 1,
  All = 2
}
export enum UITypes {
  SELFSERVICE = 'SELFSERVICE',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  AGENT = 'AGENT'
}
export enum StatusType {
  Inactive = 0,
  Active = 1,
  Delete = 2,
  Draft = 3,
  Replaced = 4,
  Archive = 5,
  Scheduled = 6
}
export const StatusTypeText = {
  0: 'Inactive',
  1: 'Active',
  2: 'Delete',
  3: 'Draft',
  4: 'Replaced'
}
export enum BroadcastType {
  USER_GENERATED = 0,
  SYSTEM_GENERATED = 1
}

export enum BroadcastPriority {
  LOW = 2,
  MEDIUM = 1,
  HIGH = 0
}

export enum EnumModuleType {
  Article = 'Article',
  DecisionTree = 'DecisionTree',
  PictureGuide = 'PictureGuide',
  FAQ = 'FAQ',
  Quiz = 'Quiz'
}
export enum CodeTypeEnum {
  Head = 'head',
  BodyStart = 'bodyStart',
  BodyEnd = 'bodyEnd',
  Style = 'customStyle'
}
export enum CodeUrlEnum {
  Head = 'headCodeUrl',
  BodyStart = 'bodyStartCodeUrl',
  BodyEnd = 'bodyEndCodeUrl',
  Style = 'customStyleCodeUrl'
}
export enum FeedbackType {
  ALL = 'ALL',
  INCORRECT_INFORMATION = 'INCORRECT INFORMATION',
  IRRELEVANT_INFORMATION = 'IRRELEVANT INFORMATION',
  GENERAL = 'GENERAL',
  SUGGESTION = 'SUGGESTION'
}

export enum FeedbackBy {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT'
}

export enum Layout {
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
  Bottom = 'Bottom'
}
export enum BackgroundLayout {
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  Middle = 'Middle'
}

export enum Model {
  GPT4 = 'gpt-4',
  DEFAULT = 'default'
}
