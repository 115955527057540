import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

/**
 *
 * @deprecated Since this component is exposing html injection vulnerability. Instead use ConformationDialogComponent
 * @export
 * @class ComformationDialogComponent
 */
@Component({
  selector: 'app-comformation-dialog',
  templateUrl: './comformation-dialog.component.html',
  styleUrls: ['./comformation-dialog.component.scss']
})
export class ComformationDialogComponent {
  title: string
  body: string
  okButtonName: string
  cancelButtonName: string
  closeButtonName: string
  constructor(public dialogRef: MatDialogRef<ComformationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true
    this.title = data.title
    this.body = data.body
    this.okButtonName = data.okButtonName
    this.cancelButtonName = data.cancelButtonName
    // this.closeButtonName = data.closeButtonName ? data.closeButtonName : null
  }

  respone(value: boolean) {
    this.dialogRef.close({ respone: value, response: value })
  }
}
