import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class FormHelper {
  constructor(private readonly fb: FormBuilder) {}

  getCourseGroup(course?) {
    return this.fb.group({
      id: [course?.id],
      name: [course?.name, [Validators.required, this.noWhitespaceValidator]],
      description: [course?.description, [Validators.required, this.noWhitespaceValidator]],
      startDate: [course?.startDate ?? new Date()],
      endDate: [course?.endDate],
      isMandatory: [course?.isMandatory ?? false],
      status: [course?.status ?? 'Draft'],
      tags: [course?.tags ?? []],
      categoryId: [course?.categoryId]
    })
  }

  getUserGroup(user?) {
    return this.fb.group({
      id: [user?.id],
      username: [user?.username, [Validators.required, this.noWhitespaceValidator]],
      firstName: [user?.firstName, [Validators.required, this.noWhitespaceValidator]],
      lastName: [user?.lastName, [Validators.required, this.noWhitespaceValidator]],
      email: [user?.email, [Validators.required, this.emailValidator, this.noWhitespaceValidator]],
      enabled: [user?.enabled ?? true, Validators.required],
      roles: [user?.roles, Validators.required]
    })
  }

  emailValidator(control?) {
    if (control.value) {
      const matches = control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
      return matches ? null : { invalidEmail: true }
    } else {
      return null
    }
  }

  getRoleGroup(role?) {
    const roleGroup = this.fb.group({
      id: role?.id,
      name: role?.name ?? ''
    })
    return roleGroup
  }

  getBatchGroup(batch?) {
    return this.fb.group({
      id: [batch?.id],
      name: [batch?.name, [Validators.required, Validators.maxLength(250), this.noWhitespaceValidator]],
      description: [batch?.description, [Validators.required, Validators.maxLength(500), this.noWhitespaceValidator]],
      startDate: [batch?.startDate ?? new Date()],
      endDate: [batch?.endDate]
    })
  }

  getQuestionGroup(question?) {
    const questionGroup = this.fb.group({
      id: question?.id,
      feedback: question?.feedback ?? '',
      type: question?.type,
      options: this.fb.array(question?.options?.map(q => this.getAnswerOptionGroup(q)) ?? []),
      title: question?.title ?? '',
      score: [question?.score ?? 1, Validators.min(1)],
      scoreObtained: question?.scoreObtained ?? 0,
      isOptional: question?.isOptional ?? false,
      sequence: question?.sequence ?? 1
    })
    const options = questionGroup.get('options') as FormArray
    if (options.length === 0) {
      options.push(this.getAnswerOptionGroup())
      options.push(this.getAnswerOptionGroup())
    }
    return questionGroup
  }

  getAnswerOptionGroup(option?) {
    return this.fb.group({
      id: option?.id,
      displayText: option?.displayText ?? '',
      title: [option?.title, Validators.required],
      isCorrect: option?.isCorrect ?? false,
      score: option?.score ?? 0,
      value: option?.value ?? undefined
    })
  }

  getQuizGroup(quiz?) {
    return this.fb.group({
      id: [quiz?.id],
      title: [quiz?.title],
      quizCompletionTimeInMinutes: [quiz?.quizCompletionTimeInMinutes ?? 0],
      areQuestionsShuffled: [quiz?.areQuestionsShuffled ?? true],
      questions: this.fb.array(quiz?.questions?.map(q => this.getQuestionGroup(q)) ?? []),
      quizAttemptLimit: [quiz?.quizAttemptLimit ?? 1, Validators.min(1)],
      startDate: [quiz?.startDate],
      endDate: [quiz?.endDate],
      evaluationCriteriaId: [quiz?.evaluationCriteriaId]
    })
  }

  getCourseModuleGroup(module) {
    return this.fb.group({
      id: [module?.id],
      title: [module?.title, [Validators.required, this.noWhitespaceValidator]],
      description: [module?.description],
      isMilestone: [module?.isMilestone],
      courseLevelId: [module?.courseLevelId],
      sequence: [module?.sequence]
    })
  }

  getCourseLevelGroup(level?) {
    return this.fb.group({
      id: [level?.id],
      name: [level?.name, [Validators.required, this.noWhitespaceValidator]],
      unlockOn: [level?.unlockOn],
      sequence: [level?.sequence ?? 1, Validators.min(1)],
      courseId: [level?.courseId]
    })
  }

  getCourseTargetGroup(target?) {
    const prerequisites = (target?.prerequisites as any[]) ?? []
    return this.fb.group({
      id: [target?.id],
      title: [target?.title, [Validators.required, this.noWhitespaceValidator]],
      sequence: [target?.sequence],
      targetType: [target?.targetType],
      status: [target?.status ?? 'Draft'],
      courseModuleId: [target?.courseModuleId],
      pages: this.fb.array(target?.pages?.map(page => this.getPageGroup(page)) ?? []),
      prerequisites: this.fb.array(prerequisites.map(p => this.fb.control(p))),
      completionInstructions: [target?.completionInstructions]
    })
  }

  getEvaluationCriteria(evaluationCriteria?) {
    return this.fb.group({
      id: [evaluationCriteria?.id],
      name: [evaluationCriteria?.name, [Validators.required, this.noWhitespaceValidator]],
      maxgrade: [evaluationCriteria?.maxgrade ?? 3],
      passinggrade: [evaluationCriteria?.passinggrade ?? 0],
      score: [evaluationCriteria?.score],
      grades: this.fb.array(evaluationCriteria?.grades?.map(g => this.getEvaluationCriteriaGrade(g)) ?? [])
    })
  }

  getEvaluationCriteriaGrade(grade?) {
    return this.fb.group({
      score: [grade?.score],
      maxScore: [grade?.maxScore, Validators.required],
      minScore: [grade?.minScore, Validators.required],
      label: [grade?.label, [Validators.required, this.noWhitespaceValidator]],
      status: [grade?.status, Validators.required]
    })
  }

  getCourseLinkPage(linkpage?) {
    return this.fb.group({
      title: [linkpage?.title],
      url: [linkpage?.url]
    })
  }

  getCourseQuizPage(quizpage?) {
    return this.fb.group({
      isOptional: [quizpage?.isOptional],
      quiz: this.getQuizGroup(quizpage?.quiz)
    })
  }

  getPageGroup(page?) {
    return this.fb.group({
      id: [page?.id || ''],
      title: [page?.title || ''],
      sequence: [page?.sequence || 0],
      type: [page?.type || '', Validators.required],
      courseLessonId: [page?.courseLessonId || ''],
      quiz: this.getQuizGroup(page?.quiz),
      quizId: [page?.quizId || ''],
      content: [page?.content || ''],
      mediaId: [page?.mediaId || null],
      mediaTitle: [page?.media?.displayName || ''],
      mediaPath: [page?.media?.path || page?.scormCourse?.entryPointPath || null]
    })
  }

  getCourseImagePage(page?) {
    return this.fb.group({
      title: [page?.title ?? ''],
      url: [page?.url ?? '']
    })
  }

  getCourseFilePage(page?) {
    return this.fb.group({
      title: [page?.title ?? ''],
      url: [page?.url ?? ''],
      scormAdminUrl: [page?.scormAdminUrl ?? '']
    })
  }

  getContentPage(contentPage?) {
    return this.fb.group({
      content: [contentPage?.content]
    })
  }

  getLinkPage(page) {
    return this.fb.group({
      sequence: [page?.sequence],
      type: 2,
      markTargetComplete: [page?.markTargetComplete],
      linkPage: this.getCourseLinkPage(page?.linkPage)
    })
  }

  getQuizPage(page) {
    return this.fb.group({
      sequence: [page?.sequence],
      type: 0,
      markTargetComplete: [page?.markTargetComplete],
      quizPage: this.getCourseQuizPage(page?.quizPage)
    })
  }

  getQuizReviewGroup(review) {
    return this.fb.group({
      questions: this.fb.array(
        (review?.questions ?? []).map(q =>
          this.fb.group({
            question: this.getQuestionGroup(q.question),
            isValid: [q.isValid ?? false]
          })
        )
      ),
      percentage: [review?.percentage || 0],
      evaluationCriteria: [review?.evaluationCriteria]
    })
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = ((control && control.value && control.value.toString()) || '').trim().length === 0
    const isValid = !isWhitespace
    return isValid ? null : { whitespace: true }
  }
}
