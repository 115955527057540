import { Component, OnInit } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import { UnleashService } from '@snowfrog/ngx-unleash-proxy-client'
import { interval, mergeMap } from 'rxjs'
import { environment } from 'src/environments/environment'

import { LocalStorageService } from './core/services/storage/local-storage.service'
import { UserEntryService } from './dashboard/components/user/pages/user-entry/user-entry.service'
import { Services } from './shared/constants/services'
import { StorageKeys } from './shared/constants/storage-keys'
import { ServiceName } from './shared/models/other-module'
import { ServiceDetail } from './shared/models/serviceDetail'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false

  title = 'saas-admin'

  constructor(
    private readonly router: Router,
    private readonly userService: UserEntryService,
    private readonly storage: LocalStorageService,
    private readonly unleash: UnleashService
  ) {
    this.initializeHeartBeat()
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true
          break
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false
          break
        }
        default: {
          break
        }
      }
    })
    this.getPermissions()
  }
  ngOnInit() {
    const userInfo = this.storage.get(StorageKeys.userInfo) as { username: string }
    if (userInfo?.username) this.initializeContext(userInfo.username)
  }

  private async initializeContext(userId: string) {
    await this.unleash.updateContext({
      userId,
      appName: environment.subdomain
    })
    return this.initializeSentryIfNeeded()
  }

  private initializeSentryIfNeeded() {
    if (this.unleash.isEnabled(ServiceName.Sentry)) {
      Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
          })
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }
  }

  initializeHeartBeat() {
    interval(5 * 60 * 1000)
      .pipe(mergeMap(() => this.userService.setHeartBeat()))
      .subscribe(res => {
        this.userService.setValue(res['message'])
      })
  }

  onMenuClick() {
    this.router.navigate(['/article'])
  }

  getPermissions() {
    const servicesAvailed = (this.storage.get(StorageKeys.serviceDetails) || []) as ServiceDetail[]
    const menuItems = []
    for (const service of servicesAvailed) {
      if (service.write == true && this.unleash.isEnabled(service?.name?.split(' ')?.join('-'))) {
        const foundService = Services.find(mi => mi.serviceCode === service?.service?.code)
        foundService.serviceId = service.serviceId
        foundService.id = service.id
        foundService.position = service.position
        menuItems.push(foundService)
      }
    }
    this.storage.set(StorageKeys.servicePermissions, menuItems)
  }
}
