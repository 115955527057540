import { Injectable } from '@angular/core'
import { ApiService } from 'src/app/core/services/http/api.service'
import { environment } from 'src/environments/environment'

@Injectable()
export class FileUploaderService {
  constructor(private readonly api: ApiService) {}

  addMedia(body) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: 'media/upload-file',
      body
    })
  }

  uploadScorm(body) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: 'media/upload-file',

      // endpoint: 'course/upload-scorm',
      body
    })
  }

  deleteMedia(id) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'delete',
      endpoint: `media/${id}`
    })
  }
}
