import { Injectable } from '@angular/core'
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import DOMPurify from 'isomorphic-dompurify'
import { customAlphabet } from 'nanoid'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { CompanyConfig } from 'src/app/shared/models/companyconfig'
import { environment } from 'src/environments/environment'

import { ApiService } from '../http/api.service'
import { LocalStorageService } from '../storage/local-storage.service'

const nanoid = customAlphabet('1234567890', 8)

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  snackbarRef: MatSnackBarRef<SimpleSnackBar>
  constructor(
    private snackbar: MatSnackBar,
    private readonly translate: TranslateService,
    private readonly localStorageService: LocalStorageService,
    private readonly api: ApiService
  ) {}

  uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  generateRandomHex(length = 6): string {
    const hexChars = '0123456789ABCDEF'
    let hexCode = '#'

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * hexChars.length)
      hexCode += hexChars[randomIndex]
    }

    return hexCode
  }
  errorHandlerMessage(message) {
    this.snackbarRef = this.snackbar.open(message, this.translate.instant('BTN.dismiss'), {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['red-snackbar']
    })
  }

  toastrMessgae(message) {
    this.snackbarRef = this.snackbar.open(message, this.translate.instant('BTN.dismiss'), {
      duration: 5000,
      verticalPosition: 'top'
    })
  }
  successToastMessage(msg) {
    this.snackbarRef = this.snackbar.open(msg, this.translate.instant('BTN.dismiss'), {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['green-snackbar']
    })
  }
  checkExtensions(files: File[], requiredExtensions: string[]) {
    for (let index = 0; index < files.length; index++) {
      const extension = files[index].name.split('.').pop()
      const compare_extension = '.' + extension
      const compare_extension_status = requiredExtensions.includes(compare_extension)
      if (!compare_extension_status) {
        this.errorHandlerMessage(this.translate.instant('ERRORMESSAGE.invalidExtension') + requiredExtensions)
        return false
      }
    }
    return true
  }
  copyToClipboard(link: string) {
    navigator.clipboard
      .writeText(link)
      .then(() => this.successToastMessage(this.translate.instant('TOOLTIPMSG.MANAGELIBRARY.URLCOPIED')))
      .catch()
  }
  getSource(href) {
    const searchPart = href.split('?').pop()
    const params = new URLSearchParams('?' + searchPart)
    if (params.get('source')) {
      return params.get('source')
    }
    return 'UI'
  }

  checkFileSize(files: { name: string; size: number; newFileName: string }[]) {
    let isFileValid = true
    const mediaSize = (this.localStorageService.get(StorageKeys.companyProfile) as CompanyConfig).mediaLimit
    const maxFileSize = mediaSize * 1024 * 1024
    files.forEach(file => {
      if (file.size > maxFileSize) {
        const errorMessage =
          this.translate.instant('TINYMCE.fileSizeExceed') + mediaSize + this.translate.instant('TINYMCE.MB') + '.'
        this.errorHandlerMessage(`${file.newFileName || file.name} ${errorMessage}`)
        isFileValid = false
      }
    })
    return isFileValid
  }

  getObjectIds(arrayOfObject) {
    if (arrayOfObject) {
      return arrayOfObject.map(obj => {
        return obj._id
      })
    } else {
      return []
    }
  }

  loadCSS(url: string) {
    if (url !== '') {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = url
      document.head.appendChild(link)
    }
  }
  sanitizeObj(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = DOMPurify.sanitize(obj[key], {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['style', 'target'] // Allow inline styles and target attribute
        })
      }
      if (typeof obj[key] === 'object') {
        this.sanitizeObj(obj[key])
      }
    }
  }
  getTranslationOverride() {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: `translation`
    })
  }
}
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value?.toString() || '')?.trim().length === 0
    const isValid = !isWhitespace
    return isValid ? null : { whitespace: true }
  }
}
export function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.readAsText(file)
  })
}
export function trimFormValues(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(key => {
    formGroup
      .get(key)
      ?.setValue(
        typeof formGroup.get(key)?.value == 'string' ? formGroup.get(key)?.value?.trim() : formGroup.get(key)?.value
      )
  })
}

export function uniqueId(prefix = '') {
  return prefix + nanoid()
}
